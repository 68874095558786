var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { height: "100" } },
    [_c("v-card-text", [_c("h2", [_vm._v("Dashboard")])])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }